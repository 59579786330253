<script>
import ChatButton from '@/components/ChatButton.vue';
import ChatWindow from '@/components/ChatWindow.vue';
import TelegramIcon from 'vue-material-design-icons/Send.vue';
import WhatsappIcon from 'vue-material-design-icons/Whatsapp.vue';
import io from "socket.io-client";
import Echo from "laravel-echo";

window.io = io;
export default {
  components: {
    ChatButton,
    ChatWindow,
    TelegramIcon,
    WhatsappIcon,
  },
  props: {
    slug: {
      type: String,
      required: true
    },
    isInline: {
      type: Boolean,
      default: false
    },
    isTest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chatColor: this.color,
      isChatOpen: false,
      isLoading: true,
      isMessagesLoaded: false,
      settings: {
        color: '#7B68EE',
        assistant_name: '',
        image_file_url: '',
        welcome_message: '',
        data_policy_url: null,
        data_policy_link_text: null,
        telegram_url: null,
        whatsapp_url: null,
        isInline: false,
        contact_data_type: null,
        auto_open_delay: null,
        is_auto_open_once: null,
      },
      messagesList: [],
      audio: null
    };
  },
  async mounted() {
    const echo = new Echo({
      broadcaster: "socket.io",
      host: 'https://api.lampabot.ru' + ':6001',
    });

    echo.channel(this.slug)
        .listen('.messageFromAssistant', (data) => {
          let uuid = localStorage.getItem('uid');
          if (data.uuid === uuid) {
            this.$refs.chatWindow.messageFromAssistant(data.message)
            this.audio.play();
          }
        });

    echo.channel(this.slug)
        .listen('.typing', (data) => {
          let uuid = localStorage.getItem('uid');
          if (data.uuid === uuid) {
            this.handleTyping();
          }
        });

    this.settings = await this.getSettings();
    let uuid = localStorage.getItem('uid');
    if (uuid) {
      const messages = await this.getMessages();
      this.messagesList = messages;
      this.isMessagesLoaded = true;
    } else {
      this.isMessagesLoaded = true;
    }
  },
  methods: {
    handleTyping() {
      this.$refs.chatWindow.showTypingIndicator();
    },
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
    async getSettings() {
      try {
        const url = process.env.VUE_APP_API_URL + this.slug + '/settings';
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Ошибка');
        }

        const result = await response.json();
        this.isLoading = false;

        if (result.success) {
          this.$nextTick(() => {
            this.audio = this.$refs.notificationSound;
            if (this.audio) {
              this.audio.load();
            }
          });
          // После загрузки настроек, вызываем метод для авто открытия чата
          this.autoOpenChat(result.settings);

          return result.settings;
        } else {
          throw new Error('Ошибка');
        }
      } catch (error) {
        console.error('Ошибка отправки запроса:', error);
      }
    },
    async getMessages() {
      try {
        let uuid = localStorage.getItem('uid');
        const url = process.env.VUE_APP_API_URL + uuid + '/messages';
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Ошибка');
        }

        const result = await response.json();
        return result.messages;
      } catch (error) {
        console.error('Ошибка отправки запроса:', error);
      }
    },
    autoOpenChat(settings) {
      const delay = settings.auto_open_delay || 0;
      const chatOpenedFlag = localStorage.getItem('chatOpened'); // Проверяем флаг
      if (settings.is_auto_open_once && chatOpenedFlag) {
        return;
      }

      if (delay === 0) {
        this.isChatOpen = true;
      } else {
        setTimeout(() => {
          this.isChatOpen = true;
          localStorage.setItem('chatOpened', 'true');
        }, delay * 1000);
      }
    },
    hexToRgb(hex) {
      hex = hex.replace(/^#/, '');

      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      return {r, g, b};
    },

    darkenColor(color, percent) {
      const rgbColor = this.hexToRgb(color);
      if (percent < 0 || percent > 100) {
        throw new Error('Процент должен быть в диапазоне от 0 до 100');
      }

      let r = Math.round(rgbColor.r * (100 - percent) / 100);
      let g = Math.round(rgbColor.g * (100 - percent) / 100);
      let b = Math.round(rgbColor.b * (100 - percent) / 100);

      return `rgb(${r}, ${g}, ${b})`;
    }
  }
}
</script>

<template>
  <div>
    <audio ref="notificationSound" preload="auto" src="https://chat.lampabot.ru/nm.mp3"></audio>
    <div v-if="!isLoading && isMessagesLoaded">
      <transition name="social-button-pulse">
        <div v-if="isChatOpen" class="social-icons ">
          <div v-if="settings.telegram_url">
            <a :href="settings.telegram_url" target="_blank" rel="noopener noreferrer" class="social-button telegram">
              <telegram-icon/>
            </a>
          </div>
          <div v-if="settings.whatsapp_url">
            <a :href="settings.whatsapp_url" target="_blank" rel="noopener noreferrer" class="social-button whatsapp">
              <whatsapp-icon/>
            </a>
          </div>
        </div>
      </transition>
      <ChatButton :color="settings.color" :isChatOpen="isChatOpen" @click="toggleChat" :darkenColor="darkenColor(settings.color, 10)" v-if="!isInline"/>
      <ChatWindow :color="settings.color" :language="settings.language" :activeColor="darkenColor(settings.color, 10)" :slug="slug" :isInline="isInline" :isTest="isTest" :isShow="isInline || isChatOpen" :assistantAvatar="settings.image_file_url" :assistantName="settings.assistant_name" :welcomeMessage="settings.welcome_message" :dataPolicyUrl="settings.data_policy_url" :dataPolicyLinkText="settings.data_policy_link_text" :contactDataType="settings.contact_data_type" :messagesList="messagesList" ref="chatWindow"/>
    </div>
    <!-- Блок для иконок Telegram и WhatsApp -->
  </div>
</template>

<style>
.social-icons {
  position: fixed;
  bottom: 44px;
  right: 110px; /* Расстояние от кнопки чата */
  display: flex;
  gap: 10px; /* Расстояние между кнопками */
  z-index: 15;
}

.social-button {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.4);
  text-decoration: none;
  cursor: pointer;
}

.social-button.telegram {
  background-color: #0088cc; /* Цвет Telegram */
}

.social-button.whatsapp {
  background-color: #25D366; /* Цвет WhatsApp */
}

.social-button.telegram:hover {
  background-color: #0077b5; /* Темнее синий цвет для Telegram */
}

.social-button.whatsapp:hover {
  background-color: #128c7e; /* Темнее зеленый цвет для WhatsApp */
}

/* Стиль внутри иконок */
.material-design-icon {
  display: inline-flex; /* Центрирует SVG внутри */
  align-items: center; /* Вертикальное выравнивание */
  justify-content: center; /* Горизонтальное выравнивание */
  width: 24px;
  height: 24px;
}

/* Анимация */
.social-button-pulse-enter-active {
  animation: pulse-in 0.4s ease;
}

.social-button-pulse-leave-active {
  animation: pulse-out 0.3s ease;
}

@keyframes pulse-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  70% {
    transform: scale(1.02);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
</style>
