export const texts = {
    en: {
        'online-chat': 'Online chat',
        'show-contacts-data': 'Please provide your contact data so we can reach you in case of disconnection',
        'send': 'Send',
        'enter-message': 'Enter message',
        'enter-your-name': 'Enter your name',
        'enter-your-email': 'Enter your email',
        'enter-your-phone': 'Enter your phone',
        'agent-is-typing': 'Agent is typing ...',
    },
    ru: {
        'online-chat': 'Онлайн чат',
        'show-contacts-data': 'Укажите ваши контактные данные, чтобы мы могли с вами связаться в случае разъединения',
        'send': 'Отправить',
        'enter-message': 'Введите сообщение',
        'enter-your-name': 'Введите ваше имя',
        'enter-your-email': 'Введите ваш email',
        'enter-your-phone': 'Введите ваш телефон',
        'agent-is-typing': 'Агент печатает ...',
    },
    de: {
        'online-chat': 'Online Chat',
        'show-contacts-data': 'Geben Sie bitte Ihre Kontaktdaten an, damit wir Sie im Falle einer Unterbrechung erreichen können',
        'send': 'Senden',
        'enter-message': 'Nachricht eingeben',
        'enter-your-name': 'Geben Sie Ihren Namen ein',
        'enter-your-email': 'Geben Sie Ihre E-Mail-Adresse ein',
        'enter-your-phone': 'Geben Sie Ihre Telefonnummer ein',
        'agent-is-typing': 'Agent schreibt ...',
    }
};
